[
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "C30D109C-FAE9-49DC-931C-84C5A1206439",
    "channelTitle": "Borås Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "99470FE4-195D-4CB5-BF22-A5E4E31EDAE1",
    "channelTitle": "Borås Tidning",
    "title": "Sport"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "6C3C7018-5F8E-4A2B-9E2A-242B2742C63A",
    "channelTitle": "Borås Tidning",
    "title": "Borås"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "A45C0901-922C-48E4-B951-B5720480E23B",
    "channelTitle": "Borås Tidning",
    "title": "Bollebygd"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "1C6A2C7B-7219-4E83-9027-E30811C1F95A",
    "channelTitle": "Borås Tidning",
    "title": "Mark"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "5F1A8A55-4B12-4B3D-9D4C-E112A12888E6",
    "channelTitle": "Borås Tidning",
    "title": "Herrljunga"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "E11A74D9-1B44-4B2A-A334-7751C383608F",
    "channelTitle": "Borås Tidning",
    "title": "Svenljunga"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "3B2B15E3-5F28-409B-BA12-D72462F04DA3",
    "channelTitle": "Borås Tidning",
    "title": "Tranemo"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "F9C32E9F-353E-4B83-A37C-8F7A982761AA",
    "channelTitle": "Borås Tidning",
    "title": "Ulricehamn"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "9D5B8A41-0C0A-4268-9D76-49D420048516",
    "channelTitle": "Borås Tidning",
    "title": "Kultur & Nöje"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "2A8B46A6-1295-4349-9157-6859D9C90A58",
    "channelTitle": "Borås Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "B33E455B-9D08-4677-A86A-585F4F3C877A",
    "channelTitle": "Borås Tidning",
    "title": "Glada nyheter"
  }
]
