module.exports = {
  '8b21920e-038d-4b79-93cc-2ef632c6d360': {
    name: 'Arbetarbladet',
    appId: 'ab',
    segments: require('./channel-segments/ab.json'),
    domain: 'arbetarbladet.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '8772fd06-022d-4c77-92ad-82ab6a5a50b7': {
    name: 'Allehanda',
    appId: 'allehanda',
    segments: require('./channel-segments/allehanda.json'),
    domain: 'allehanda.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'a0a2b200-c21b-46db-b9b4-34b32f7b53be': {
    name: 'Avesta Tidning',
    appId: 'at',
    segments: require('./channel-segments/at.json'),
    domain: 'avestatidning.com',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'd61de145-70b2-475f-859c-62612593cfbc': {
    name: 'Barometern',
    appId: 'ba',
    segments: require('./channel-segments/ba.json'),
    domain: 'barometern.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '11de602f-230d-4821-8c80-9c605b54a7a3': {
    name: 'Bbl/AT',
    appId: 'bb',
    segments: require('./channel-segments/bb.json'),
    domain: 'bblat.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '0e31e681-eece-4b73-92f5-2611d6cd0764': {
    name: 'Borgåbladet',
    appId: 'ony',
    segments: require('./channel-segments/ony.json'),
    domain: 'bbl.fi',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '01b2789d-453d-4f47-9ed2-6b0edd6925fa': {
    name: 'Bleking Läns Tidning',
    appId: 'blt',
    segments: require('./channel-segments/blt.json'),
    domain: 'blt.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '502764de-54d3-4107-b14e-bcfa0771246f': {
    name: 'Borås Tidning',
    appId: 'boras',
    segments: require('./channel-segments/boras.json'),
    domain: 'bt.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '357b1ad5-8bcb-4eff-bd11-d1b232f4858b': {
    name: 'Bandypuls',
    appId: 'bp',
    segments: require('./channel-segments/bp.json'),
    domain: 'bandypuls.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '67b7c678-4b80-43ce-a931-2a65e32c6276': {
    name: 'Borlänge Tidning',
    appId: 'bt',
    segments: require('./channel-segments/bt.json'),
    domain: 'borlangetidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '7b087c6c-3ef5-4a0e-b2d5-5c1f2612d715': {
    name: 'Dala-Demokraten',
    appId: 'dd',
    segments: require('./channel-segments/dd.json'),
    domain: 'dalademokraten.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '653bcbae-f5c2-4cf5-b301-c396084dcbd9': {
    name: 'Falu-Kuriren',
    appId: 'fk',
    segments: require('./channel-segments/fk.json'),
    domain: 'falukuriren.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '92a00880-5bf9-4dc8-8202-44e6c8c35bde': {
    name: 'Fagersta-Posten',
    appId: 'fp',
    segments: require('./channel-segments/fp.json'),
    domain: 'fagersta-posten.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'a7a4e7ac-b49f-485e-ac75-219833f8800f': {
    name: 'Folkracepuls',
    appId: 'frp',
    segments: require('./channel-segments/frp.json'),
    domain: 'folkracepuls.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'b9f133af-d585-4f82-b2cf-1b0f096be489': {
    name: 'Falköpings Tidning',
    appId: 'ft',
    segments: require('./channel-segments/ft.json'),
    domain: 'falkopingstidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '069380d2-8ec9-457b-a285-a4cd40c36367': {
    name: 'Gefle Dagblad',
    appId: 'gd',
    segments: require('./channel-segments/gd.json'),
    domain: 'gd.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '0519f7ad-0d77-4e34-9b4d-1e0eaea80c14': {
    name: 'Helsingborgs Dagblad',
    appId: 'hd',
    segments: require('./channel-segments/hd.json'),
    domain: 'hd.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '671b0ca6-4a2d-49b1-a2b9-52ea93fe12da': {
    name: 'Hudiksvalls Tidning',
    appId: 'ht',
    segments: require('./channel-segments/ht.json'),
    domain: 'ht.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'cf260058-2e86-4545-a38e-1d29bc63fb43': {
    name: 'Hufvudstadsbladet',
    appId: 'hbl',
    segments: require('./channel-segments/hbl.json'),
    domain: 'hbl.fi',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '2b5532fd-dad7-48e0-a4a8-be673aaafc78': {
    name: 'Jönköpings-Posten',
    appId: 'jp',
    segments: require('./channel-segments/jp.json'),
    domain: 'jp.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '80d66df2-32f3-4233-8fdc-691b9c9589c8': {
    name: 'Kristianstadsbladet',
    appId: 'kb',
    segments: require('./channel-segments/kb.json'),
    domain: 'kristianstadsbladet.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '111c947b-6244-4334-abe0-64b58733223c': {
    name: 'Kalmar Läns Tidning',
    appId: 'klt',
    segments: require('./channel-segments/klt.json'),
    domain: 'klt.nu',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'ca972392-b1ac-4f6e-9230-a6c2c1c6729b': {
    name: 'Ljusnan',
    appId: 'lj',
    segments: require('./channel-segments/lj.json'),
    domain: 'ljusnan.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '86142e97-ac68-48e9-925b-21f26186d988': {
    name: 'Ljusdals-Posten',
    appId: 'ljp',
    segments: require('./channel-segments/ljp.json'),
    domain: 'ljusdalsposten.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'd28290ba-c842-437f-93ac-631d0b9d1b28': {
    name: 'LT',
    appId: 'lt',
    segments: require('./channel-segments/lt.json'),
    domain: 'lt.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '5f827d27-768b-49b8-9b00-5614af6d60da': {
    name: 'LTZ',
    appId: 'ltz',
    segments: require('./channel-segments/ltz.json'),
    domain: 'ltz.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'a2112bca-8509-45b8-adc7-65a58019edd6': {
    name: 'Mora Tidning',
    appId: 'mt',
    segments: require('./channel-segments/mt.json'),
    domain: 'moratidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '02569f44-187a-4171-860a-123edf2e5e5e': {
    name: 'Nerikes Allehanda',
    appId: 'na',
    segments: require('./channel-segments/na.json'),
    domain: 'na.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'd4c7174d-995e-4efa-a4e8-ff448cd52132': {
    name: 'Nu i Österåker',
    appId: 'nio',
    segments: require('./channel-segments/nio.json'),
    domain: 'nuiosteraker.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '50b45365-8ee0-43f3-b89c-e29a626cd02a': {
    name: 'Nya Ludvika Tidning',
    appId: 'nlt',
    segments: require('./channel-segments/nlt.json'),
    domain: 'nyaludvikatidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'ce6ec877-b9c2-40b5-a6f3-02f5036bb7be': {
    name: 'Nynäshamns Posten',
    appId: 'np',
    segments: require('./channel-segments/np.json'),
    domain: 'nynashamnsposten.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '99f13994-5a99-4e28-80e2-904664e238aa': {
    name: 'Norra Skåne',
    appId: 'nsk',
    segments: require('./channel-segments/nsk.json'),
    domain: 'nsk.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '8487f84a-e231-4d9c-bd97-abc0bdec3487': {
    name: 'Norrtelje Tidning',
    appId: 'nt',
    segments: require('./channel-segments/nt.json'),
    domain: 'norrteljetidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'b8709456-a15e-46cb-b706-6eb10d5449c5': {
    name: 'Nacka Värmdö Posten',
    appId: 'nvp',
    segments: require('./channel-segments/nvp.json'),
    domain: 'nvp.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '4ce1fe0e-7120-4aea-a5ee-6bcc5243efc0': {
    name: 'Ölandsbladet',
    appId: 'ob',
    segments: require('./channel-segments/ob.json'),
    domain: 'olandsbladet.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '98f288a4-b44f-401a-b038-9d3a8694869f': {
    name: 'Östersunds-Posten',
    appId: 'op',
    segments: require('./channel-segments/op.json'),
    domain: 'op.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '301bdc82-7041-4e98-a8ae-78904bdc1ae4': {
    name: 'Sala Allehanda',
    appId: 'sa',
    segments: require('./channel-segments/sa.json'),
    domain: 'salaallehanda.com',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '57c58b41-38c9-4955-b3ef-5fa13fe2d559': {
    name: 'Södra Dalarnes Tidning',
    appId: 'sdt',
    segments: require('./channel-segments/sdt.json'),
    domain: 'sodran.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'dd60b489-36f9-41d5-930d-0d9be47a715b': {
    name: 'Söderhamns-Kuriren',
    appId: 'sk',
    segments: require('./channel-segments/sk.json'),
    domain: 'soderhamnskuriren.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '86bbf49d-c5c3-43d5-9874-aa2d91300f20': {
    name: 'Skånska Dagbladet',
    appId: 'skd',
    segments: require('./channel-segments/skd.json'),
    domain: 'skd.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'bea790c3-03a7-44a8-9ccf-693e8e1e72fe': {
    name: 'SkLT',
    appId: 'sklt',
    segments: require('./channel-segments/sklt.json'),
    domain: 'skaraborgslanstidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '6a00ec61-3c7f-4427-8f90-1d2bd89e9a29': {
    name: 'Smålands Dagblad',
    appId: 'smd',
    segments: require('./channel-segments/smd.json'),
    domain: 'smalandsdagblad.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'eb3cb54e-0661-47db-a723-2334af94beae': {
    name: 'Smålänningen',
    appId: 'sml',
    segments: require('./channel-segments/sml.json'),
    domain: 'smalanningen.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'c73749ce-6bce-44d8-8128-eb981fbc544e': {
    name: 'Smålandsposten',
    appId: 'smp',
    segments: require('./channel-segments/smp.json'),
    domain: 'smp.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'b27a7f7a-5675-4f51-9fd6-d96a4b8b731a': {
    name: 'Smålands-Tidningen',
    appId: 'smt',
    segments: require('./channel-segments/smt.json'),
    domain: 'smt.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '32f2acc2-a70f-47c6-87ab-7bcb780e1ea8': {
    name: 'Skövde Nyheter',
    appId: 'sn',
    segments: require('./channel-segments/sn.json'),
    domain: 'skovdenyheter.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '36604dc8-3500-4ea7-9f50-80b9d8d83ae5': {
    name: 'Sundsvalls Tidning',
    appId: 'st',
    segments: require('./channel-segments/st.json'),
    domain: 'st.nu',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '86ffbff5-7296-485f-95cf-256ce05c15a5': {
    name: 'Sydöstran',
    appId: 'sydostran',
    segments: require('./channel-segments/sydostran.json'),
    domain: 'sydostran.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '72bac164-d40d-4cd1-8f3a-9913410784fe': {
    name: 'Sydsvenskan',
    appId: 'sydsvenskan',
    segments: require('./channel-segments/sydsvenskan.json'),
    domain: 'sydsvenskan.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '3311201e-7d46-49c4-a30e-516d1fef58c8': {
    name: 'Tidningen Ångermanland',
    appId: 'ta',
    segments: require('./channel-segments/ta.json'),
    domain: 'tidningenangermanland.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'f8424ca0-c794-4dab-9943-8fb646d5c649': {
    name: 'Trelleborgs Allehanda',
    appId: 'tba',
    segments: require('./channel-segments/tba.json'),
    domain: 'trelleborgsallehanda.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '17e7c5f3-cf7c-4f89-a7ad-89154d8ea060': {
    name: 'Tidningen Härjedalen',
    appId: 'th',
    segments: require('./channel-segments/th.json'),
    domain: 'tidningenharjedalen.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'debfcdec-92f9-421d-a18e-bef30774b3c9': {
    name: 'Tranås Tidning',
    appId: 'trt',
    segments: require('./channel-segments/trt.json'),
    domain: 'tranastidning.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'a2804487-7a24-4cff-98e2-6f70451ead76': {
    name: 'Ulricehamns Tidning',
    appId: 'ut',
    segments: require('./channel-segments/ut.json'),
    domain: 'ut.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'def82572-9912-4710-aab7-70fc13685821': {
    name: 'Västgöta-Bladet',
    appId: 'vb',
    segments: require('./channel-segments/vb.json'),
    domain: 'vastgotabladet.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '9eac0e19-ad2d-443b-a5c0-e598f233fff1': {
    name: 'Vaxjöbladet Kronobergaren',
    appId: 'vk',
    segments: require('./channel-segments/vk.json'),
    domain: 'vaxjöbladet.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '250f1b71-0763-435b-867c-6ee9028dbe3c': {
    name: 'VLT',
    appId: 'vlt',
    segments: require('./channel-segments/vlt.json'),
    domain: 'vlt.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  '24c7d6cc-0d99-43a4-98b8-0cad34faef78': {
    name: 'Värnamo Nyheter',
    appId: 'vn',
    segments: require('./channel-segments/vn.json'),
    domain: 'vn.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'a8e80a51-9856-4383-93d6-ea8e4e3e12c0': {
    appId: 'vny',
    name: 'Västra Nyland',
    segments: require('./channel-segments/vny.json'),
    domain: 'vastranyland.fi',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'e39aac2d-6f27-48c3-b887-ad25ae9ca23d': {
    name: 'Vetlanda-Posten',
    appId: 'vp',
    segments: require('./channel-segments/vp.json'),
    domain: 'vetlandaposten.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'f62b0e9a-96e3-4128-9899-6624e09c0f91': {
    name: 'Ystads Allehanda',
    appId: 'ya',
    segments: require('./channel-segments/ya.json'),
    domain: 'ystadsallehanda.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: 'b9e54aab-b0cd-431d-a406-178c7274b94a',
  },
  'e5a6c16a-3740-439e-afc7-6b3ec5287b9f': {
    name: 'Dagens industri',
    appId: 'di',
    segments: require('./channel-segments/di.json'),
    domain: 'di.se',
    priorities: [
      'organisations',
      'authors',
      'stories',
      'sections',
      'locations',
      'categories',
    ],
    publishingGroup: '0f841722-7beb-4b15-99a7-28fb71b29beb',
  },
};
