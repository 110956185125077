[
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "AF7409A4-5539-4DAC-B37A-2C93A6F9DC69",
    "channelTitle": "Sydöstran",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "7EE5380E-0065-4001-836A-063B8FE50725",
    "channelTitle": "Sydöstran",
    "title": "Sport"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "71EFF98F-39B9-40F5-849D-0FFFEC5B4085",
    "channelTitle": "Sydöstran",
    "title": "Karlskrona"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "27B1F424-459F-480D-9E6B-A9C5DD3AFCE3",
    "channelTitle": "Sydöstran",
    "title": "Ronneby"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "168B3811-F75C-48FD-AEE2-3727FD23FED7",
    "channelTitle": "Sydöstran",
    "title": "Karlshamn"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "E3BE1885-114C-41FC-86E9-4D18C04430D7",
    "channelTitle": "Sydöstran",
    "title": "Sölvesborg"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "244BDA4F-60CC-4DD8-A346-112C08071CD4",
    "channelTitle": "Sydöstran",
    "title": "Olofström"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "AE4443EA-D2D4-4B17-919C-D480B1101E87",
    "channelTitle": "Sydöstran",
    "title": "Näringsliv"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "AAF7866B-5355-4DD7-BF9B-9D65057C07D2",
    "channelTitle": "Sydöstran",
    "title": "Mjällby AIF"
  }
]
