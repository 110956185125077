module.exports = {
  '8b21920e-038d-4b79-93cc-2ef632c6d360': {
    icon: require('~/components/icons/channel-icons/ab').default,
  },
  '8772fd06-022d-4c77-92ad-82ab6a5a50b7': {
    icon: require('~/components/icons/channel-icons/allehanda').default,
  },
  'a0a2b200-c21b-46db-b9b4-34b32f7b53be': {
    icon: require('~/components/icons/channel-icons/at').default,
  },
  'd61de145-70b2-475f-859c-62612593cfbc': {
    icon: require('~/components/icons/channel-icons/ba').default,
  },
  '11de602f-230d-4821-8c80-9c605b54a7a3': {
    icon: require('~/components/icons/channel-icons/bb').default,
  },
  '01b2789d-453d-4f47-9ed2-6b0edd6925fa': {
    icon: require('~/components/icons/channel-icons/blt').default,
  },
  '502764de-54d3-4107-b14e-bcfa0771246f': {
    icon: require('~/components/icons/channel-icons/boras').default,
  },
  '357b1ad5-8bcb-4eff-bd11-d1b232f4858b': {
    icon: require('~/components/icons/channel-icons/bp').default,
  },
  '67b7c678-4b80-43ce-a931-2a65e32c6276': {
    icon: require('~/components/icons/channel-icons/bt').default,
  },
  '7b087c6c-3ef5-4a0e-b2d5-5c1f2612d715': {
    icon: require('~/components/icons/channel-icons/dd').default,
  },
  '653bcbae-f5c2-4cf5-b301-c396084dcbd9': {
    icon: require('~/components/icons/channel-icons/fk').default,
  },
  '92a00880-5bf9-4dc8-8202-44e6c8c35bde': {
    icon: require('~/components/icons/channel-icons/fp').default,
  },
  'a7a4e7ac-b49f-485e-ac75-219833f8800f': {
    icon: require('~/components/icons/channel-icons/frp').default,
  },
  'b9f133af-d585-4f82-b2cf-1b0f096be489': {
    icon: require('~/components/icons/channel-icons/ft').default,
  },
  '069380d2-8ec9-457b-a285-a4cd40c36367': {
    icon: require('~/components/icons/channel-icons/gd').default,
  },
  'cf260058-2e86-4545-a38e-1d29bc63fb43': {
    icon: require('~/components/icons/channel-icons/hbl').default,
  },
  '0519f7ad-0d77-4e34-9b4d-1e0eaea80c14': {
    icon: require('~/components/icons/channel-icons/hd').default,
  },
  '671b0ca6-4a2d-49b1-a2b9-52ea93fe12da': {
    icon: require('~/components/icons/channel-icons/ht').default,
  },
  '2b5532fd-dad7-48e0-a4a8-be673aaafc78': {
    icon: require('~/components/icons/channel-icons/jp').default,
  },
  '80d66df2-32f3-4233-8fdc-691b9c9589c8': {
    icon: require('~/components/icons/channel-icons/kb').default,
  },
  '111c947b-6244-4334-abe0-64b58733223c': {
    icon: require('~/components/icons/channel-icons/klt').default,
  },
  'ca972392-b1ac-4f6e-9230-a6c2c1c6729b': {
    icon: require('~/components/icons/channel-icons/lj').default,
  },
  '86142e97-ac68-48e9-925b-21f26186d988': {
    icon: require('~/components/icons/channel-icons/ljp').default,
  },
  'd28290ba-c842-437f-93ac-631d0b9d1b28': {
    icon: require('~/components/icons/channel-icons/lt').default,
  },
  '5f827d27-768b-49b8-9b00-5614af6d60da': {
    icon: require('~/components/icons/channel-icons/ltz').default,
  },
  'a2112bca-8509-45b8-adc7-65a58019edd6': {
    icon: require('~/components/icons/channel-icons/mt').default,
  },
  '02569f44-187a-4171-860a-123edf2e5e5e': {
    icon: require('~/components/icons/channel-icons/na').default,
  },
  'd4c7174d-995e-4efa-a4e8-ff448cd52132': {
    icon: require('~/components/icons/channel-icons/nio').default,
  },
  '50b45365-8ee0-43f3-b89c-e29a626cd02a': {
    icon: require('~/components/icons/channel-icons/nlt').default,
  },
  'ce6ec877-b9c2-40b5-a6f3-02f5036bb7be': {
    icon: require('~/components/icons/channel-icons/np').default,
  },
  '99f13994-5a99-4e28-80e2-904664e238aa': {
    icon: require('~/components/icons/channel-icons/nsk').default,
  },
  '8487f84a-e231-4d9c-bd97-abc0bdec3487': {
    icon: require('~/components/icons/channel-icons/nt').default,
  },
  'b8709456-a15e-46cb-b706-6eb10d5449c5': {
    icon: require('~/components/icons/channel-icons/nvp').default,
  },
  '4ce1fe0e-7120-4aea-a5ee-6bcc5243efc0': {
    icon: require('~/components/icons/channel-icons/ob').default,
  },
  '0e31e681-eece-4b73-92f5-2611d6cd0764': {
    icon: require('~/components/icons/channel-icons/ony').default,
  },
  '98f288a4-b44f-401a-b038-9d3a8694869f': {
    icon: require('~/components/icons/channel-icons/op').default,
  },
  '301bdc82-7041-4e98-a8ae-78904bdc1ae4': {
    icon: require('~/components/icons/channel-icons/sa').default,
  },
  '57c58b41-38c9-4955-b3ef-5fa13fe2d559': {
    icon: require('~/components/icons/channel-icons/sdt').default,
  },
  'dd60b489-36f9-41d5-930d-0d9be47a715b': {
    icon: require('~/components/icons/channel-icons/sk').default,
  },
  '86bbf49d-c5c3-43d5-9874-aa2d91300f20': {
    icon: require('~/components/icons/channel-icons/skd').default,
  },
  'bea790c3-03a7-44a8-9ccf-693e8e1e72fe': {
    icon: require('~/components/icons/channel-icons/sklt').default,
  },
  '6a00ec61-3c7f-4427-8f90-1d2bd89e9a29': {
    icon: require('~/components/icons/channel-icons/smd').default,
  },
  'eb3cb54e-0661-47db-a723-2334af94beae': {
    icon: require('~/components/icons/channel-icons/sml').default,
  },
  'c73749ce-6bce-44d8-8128-eb981fbc544e': {
    icon: require('~/components/icons/channel-icons/smp').default,
  },
  'b27a7f7a-5675-4f51-9fd6-d96a4b8b731a': {
    icon: require('~/components/icons/channel-icons/smt').default,
  },
  '32f2acc2-a70f-47c6-87ab-7bcb780e1ea8': {
    icon: require('~/components/icons/channel-icons/sn').default,
  },
  '36604dc8-3500-4ea7-9f50-80b9d8d83ae5': {
    icon: require('~/components/icons/channel-icons/st').default,
  },
  '86ffbff5-7296-485f-95cf-256ce05c15a5': {
    icon: require('~/components/icons/channel-icons/sydostran').default,
  },
  '72bac164-d40d-4cd1-8f3a-9913410784fe': {
    icon: require('~/components/icons/channel-icons/sydsvenskan').default,
  },
  '3311201e-7d46-49c4-a30e-516d1fef58c8': {
    icon: require('~/components/icons/channel-icons/ta').default,
  },
  'f8424ca0-c794-4dab-9943-8fb646d5c649': {
    icon: require('~/components/icons/channel-icons/tba').default,
  },
  '17e7c5f3-cf7c-4f89-a7ad-89154d8ea060': {
    icon: require('~/components/icons/channel-icons/th').default,
  },
  'debfcdec-92f9-421d-a18e-bef30774b3c9': {
    icon: require('~/components/icons/channel-icons/trt').default,
  },
  'a2804487-7a24-4cff-98e2-6f70451ead76': {
    icon: require('~/components/icons/channel-icons/ut').default,
  },
  'def82572-9912-4710-aab7-70fc13685821': {
    icon: require('~/components/icons/channel-icons/vb').default,
  },
  '9eac0e19-ad2d-443b-a5c0-e598f233fff1': {
    icon: require('~/components/icons/channel-icons/vk').default,
  },
  '250f1b71-0763-435b-867c-6ee9028dbe3c': {
    icon: require('~/components/icons/channel-icons/vlt').default,
  },
  '24c7d6cc-0d99-43a4-98b8-0cad34faef78': {
    icon: require('~/components/icons/channel-icons/vn').default,
  },
  'a8e80a51-9856-4383-93d6-ea8e4e3e12c0': {
    icon: require('~/components/icons/channel-icons/vny').default,
  },
  'e39aac2d-6f27-48c3-b887-ad25ae9ca23d': {
    icon: require('~/components/icons/channel-icons/vp').default,
  },
  'f62b0e9a-96e3-4128-9899-6624e09c0f91': {
    icon: require('~/components/icons/channel-icons/ya').default,
  },
  'e5a6c16a-3740-439e-afc7-6b3ec5287b9f': {
    icon: require('~/components/icons/channel-icons/di').default,
  },
};
