[
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "D4BB4341-9020-474A-BBA0-3B3E41D48D06",
    "channelTitle": "Gefle Dagblad",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "6F9619FF-8B86-D011-B42D-00C04FC964FF",
    "channelTitle": "Gefle Dagblad",
    "title": "Blåljus"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "B3EE1C8A-6973-42B9-BD7A-093B5ACB0BFC",
    "channelTitle": "Gefle Dagblad",
    "title": "Kultur & nöje"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "DA35A1AA-9B36-43CF-AF0A-77F619FA0D50",
    "channelTitle": "Gefle Dagblad",
    "title": "Näringsliv"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "9116F367-35B1-4B3B-AC7C-97C051EC5D31",
    "channelTitle": "Gefle Dagblad",
    "title": "Opinion"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "403B6E95-9CAB-4AA7-8236-4647F4BE92C8",
    "channelTitle": "Gefle Dagblad",
    "title": "Sport"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "9B7C52E9-92E7-42D8-9AC9-39376861F2C7",
    "channelTitle": "Gefle Dagblad",
    "title": "Brynäs IF Dam"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "7978D29F-DDE0-407E-9455-442CF8B81C3E",
    "channelTitle": "Gefle Dagblad",
    "title": "Brynäs IF Herr"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "985243A4-6D78-4475-AAF0-E650FB8A3289",
    "channelTitle": "Gefle Dagblad",
    "title": "Strömsbro IF Hockey"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "E52AA0C2-267E-4F76-BD0B-C33222177271",
    "channelTitle": "Gefle Dagblad",
    "title": "Gefle IF"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "C162B71B-CC7D-4203-ACBA-1D2B95445ACE",
    "channelTitle": "Gefle Dagblad",
    "title": "Sandvikens IF"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "CC104E54-6282-483C-844C-20F6C063ACB3",
    "channelTitle": "Gefle Dagblad",
    "title": "SAIK Bandy"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "44E82CF3-496A-4B9B-9DB4-607B9B1A2DFD",
    "channelTitle": "Gefle Dagblad",
    "title": "GGIK innebandy"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "F21E27A2-5E39-40FF-8650-1D3BED0D7168",
    "channelTitle": "Gefle Dagblad",
    "title": "Trafik"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "D93D6E28-2BE2-4EDB-94C9-888A953717FE",
    "channelTitle": "Gefle Dagblad",
    "title": "Gävle kommun"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "3B7B36A6-4EAE-4CA0-A4E6-9059A74FE723",
    "channelTitle": "Gefle Dagblad",
    "title": "Hofors kommun"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "B4AE5FCC-A9B8-4633-8804-A8C79CE360DD",
    "channelTitle": "Gefle Dagblad",
    "title": "Ockelbo kommun"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "5895A3D2-DB57-4756-B73E-732F9CD64FBA",
    "channelTitle": "Gefle Dagblad",
    "title": "Sandvikens kommun"
  },
  {
    "channel": "069380d2-8ec9-457b-a285-a4cd40c36367",
    "uuid": "F362D7ED-5C5D-489C-81D1-72D5C946F673",
    "channelTitle": "Gefle Dagblad",
    "title": "Älvkarleby kommun"
  }
]
