[
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "304FC956-91AB-444B-A143-D08A33B62841",
    "channelTitle": "Smålandsposten",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "92F7154C-676D-4DF2-AC08-E53BE7F9D169",
    "channelTitle": "Smålandsposten",
    "title": "Sport"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "F7A9B2C1-9D8A-4A23-8F4E-5B3C7A1D9E2F",
    "channelTitle": "Smålandsposten",
    "title": "Växjö"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "3E6D8C1B-5A7F-4B9D-8E3A-2C1F5B4A8D7E",
    "channelTitle": "Smålandsposten",
    "title": "Alvesta"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "9B2C5A7F-1D4E-4B8A-9C3F-2E1A4D7B6C8A",
    "channelTitle": "Smålandsposten",
    "title": "Älmhult"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "1A2B3C4D-5E6F-4A8B-9C0D-1E2F3A4B5C6D",
    "channelTitle": "Smålandsposten",
    "title": "Tingsryd"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "7F8E9D0C-1B2A-4E6F-8A9D-0C1E2F3A4B5C",
    "channelTitle": "Smålandsposten",
    "title": "Uppvidinge"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "2D3E4F5A-6B7C-4A8D-9E0F-1A2B3C4D5E6F",
    "channelTitle": "Smålandsposten",
    "title": "Växjö Lakers"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "8A9B0C1D-2E3F-4A5B-6C7D-8E9F0A1B2C3D",
    "channelTitle": "Smålandsposten",
    "title": "Lessebo"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "4E5F6A7B-8C9D-4E0F-1A2B-3C4D5E6F7A8B",
    "channelTitle": "Smålandsposten",
    "title": "Ljungby"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "0C1D2E3F-4A5B-4C7D-8E9F-0A1B2C3D4E5F",
    "channelTitle": "Smålandsposten",
    "title": "Östers IF"
  }
]
